import React from "react";
import theme from "theme";
import { Theme, Strong, Text, Section, Icon, Box, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { MdDone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Study Prosper Hub
			</title>
			<meta name={"description"} content={"Водійські права — це не лише документ, а й свобода пересування, нові можливості та впевненість у собі."} />
			<meta property={"og:title"} content={"Study Prosper Hub"} />
			<meta property={"og:description"} content={"Водійські права — це не лише документ, а й свобода пересування, нові можливості та впевненість у собі."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/Wright-Illustrations-adult-1110x734x0x0x1110x734x1680136260.png?v=2024-11-25T15:51:08.157Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/Wright-Illustrations-adult-1110x734x0x0x1110x734x1680136260.png?v=2024-11-25T15:51:08.157Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/Wright-Illustrations-adult-1110x734x0x0x1110x734x1680136260.png?v=2024-11-25T15:51:08.157Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/Wright-Illustrations-adult-1110x734x0x0x1110x734x1680136260.png?v=2024-11-25T15:51:08.157Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/Wright-Illustrations-adult-1110x734x0x0x1110x734x1680136260.png?v=2024-11-25T15:51:08.157Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/Wright-Illustrations-adult-1110x734x0x0x1110x734x1680136260.png?v=2024-11-25T15:51:08.157Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/Wright-Illustrations-adult-1110x734x0x0x1110x734x1680136260.png?v=2024-11-25T15:51:08.157Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text">
				Study Prosper Hub
			</Override>
		</Components.Header>
		<Section
			background="linear-gradient(0deg,rgba(3, 4, 48, 0.79) 0%,--color-primary 100%),--color-light url(https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/Wright-Illustrations-adult-1110x734x0x0x1110x734x1680136260.png?v=2024-11-25T15:51:08.157Z) center/cover"
			padding="120px 0 120px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
						У{" "}
						<Strong>
							Study Prosper Hub
						</Strong>
						{" "}ми допомагаємо зробити цей важливий крок, забезпечуючи якісне навчання водінню для початківців та вдосконалення навичок для досвідчених водіїв.
					</Text>
					<Text
						as="h1"
						font="--headline2"
						md-font="--headline2"
						margin="10px 0"
						width="100%"
					>
						Водійські права — це не лише документ, а й свобода пересування, нові можливості та впевненість у собі.{" "}
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<Section padding="80px 0 80px 0" background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/blurred-gradient-colorful-background.jpg?v=2023-04-27T14:13:16.718Z) 50% 50% /cover repeat scroll padding-box" quarkly-title="Content-7">
			<Override
				slot="SectionContent"
				padding="0px 0 0px 0px"
				lg-padding="0px 0 0px 0px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="center"
				lg-grid-template-columns="1fr"
			/>
			<Box min-width="100px" min-height="100px">
				<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
					<Strong>
						Як проходить навчання у Study Prosper Hub?
					</Strong>
				</Text>
				<Text margin="0px 0px 25px 0px" font="--lead" color="--darkL1">
					<Strong>
						Досвідчені інструктори:
					</Strong>
					{" "}У нашій команді працюють сертифіковані професіонали з багаторічним досвідом. Ми навчаємо не лише технічним навичкам, а й правильній реакції в реальних дорожніх ситуаціях.
					<br />
					<br />
					<Strong>
						Сучасний автопарк:
					</Strong>
					{" "}Ми пропонуємо навчання на автомобілях, обладнаних усім необхідним для комфортного навчання. Ви зможете обрати авто з механічною або автоматичною коробкою передач.
					<br />
					<br />
					<Strong>
						Індивідуальний підхід:
					</Strong>
					{" "}Різні люди мають різні потреби, і ми це розуміємо. Програма навчання розробляється відповідно до вашого рівня підготовки, графіку та мети.
					<br />
					<br />
					<Strong>
						Гнучкий графік занять:
					</Strong>
					{" "}Ми пропонуємо ранкові, денні та вечірні уроки, а також заняття у вихідні дні для вашої зручності.
					<br />
					<br />
					<Strong>
						Високий відсоток успішності:
					</Strong>
					{" "}Завдяки нашим методам навчання більшість наших студентів успішно складають іспити з першого разу.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="--primary"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="--lead" color="#505257">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Реєстрація
							<br />
						</Strong>
						{" "}Зателефонуйте нам і аша команда допоможе вам обрати програму та скласти графік занять.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="--primary"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="--lead" color="#505257">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Теоретичний курс
							<br />
						</Strong>
						Ви вивчите правила дорожнього руху, дорожні знаки, основи автомобільної механіки та техніки безпеки.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 35px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="--primary"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="--lead" color="#505257">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Практичні заняття
							<br />
						</Strong>
						Ми навчимо вас основам водіння, маневрування, паркування та поведінки в різних дорожніх ситуаціях. Наші інструктори працюють з вами індивідуально.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 35px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="--primary"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="--lead" color="#505257">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Іспит
							<br />
						</Strong>
						Ми допоможемо вам підготуватися до теоретичного та практичного іспиту, щоб отримати водійські права.
					</Text>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0px 0px 0px 25px"
				border-width="0 0 0 2px"
				border-style="solid"
				border-color="#b8bcc0"
				margin="0px 0px 35px 80px"
				lg-margin="40px 0px 35px 0"
			>
				<Text margin="0px 0px 35px 0px" font="--lead">
					"Я вже мав водійські права, але боявся сідати за кермо. Після курсів у Study Prosper Hub я нарешті почуваюся впевнено на дорозі!"
				</Text>
				<Box min-width="10px" min-height="10px" display="flex" align-items="center">
					<Image
						src="https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/7q.jpg?v=2023-04-18T18:56:53.857Z"
						display="block"
						border-radius="50%"
						width="55px"
						height="55px"
						min-width="55px"
						min-height="55px"
						margin="0px 15px 0px 0px"
						srcSet="https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/7q.jpg?v=2023-04-18T18%3A56%3A53.857Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/7q.jpg?v=2023-04-18T18%3A56%3A53.857Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/7q.jpg?v=2023-04-18T18%3A56%3A53.857Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/7q.jpg?v=2023-04-18T18%3A56%3A53.857Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/7q.jpg?v=2023-04-18T18%3A56%3A53.857Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/7q.jpg?v=2023-04-18T18%3A56%3A53.857Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/7q.jpg?v=2023-04-18T18%3A56%3A53.857Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 0px 0px" font="--base">
						— Андрій, 55 років
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px" quarkly-title="Advantages/Features-24" background="--color-light">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 2rem 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					lg-width="100%"
				>
					Наші послуги
				</Text>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Icon
						category="md"
						icon={MdDone}
						size="36px"
						background="--color-secondary"
						color="--light"
						border-radius="50%"
						padding="8px 8px 8px 8px"
					/>
					<Box margin="0px 0px 0px 22px" sm-margin="0px 0px 0px 12px">
						<Text
							margin="0px 0px 0 0px"
							color="--darkL2"
							font="--headline3"
							lg-text-align="left"
							sm-font="--lead"
						>
							Курси для початківців
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Icon
						category="md"
						icon={MdDone}
						size="36px"
						background="--color-secondary"
						color="--light"
						border-radius="50%"
						padding="8px 8px 8px 8px"
					/>
					<Box margin="0px 0px 0px 22px" sm-margin="0px 0px 0px 12px">
						<Text
							margin="0px 0px 0 0px"
							color="--darkL2"
							font="--headline3"
							lg-text-align="left"
							sm-font="--lead"
						>
							Курси для вдосконалення
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Icon
						category="md"
						icon={MdDone}
						size="36px"
						background="--color-secondary"
						color="--light"
						border-radius="50%"
						padding="8px 8px 8px 8px"
					/>
					<Box margin="0px 0px 0px 22px" sm-margin="0px 0px 0px 12px">
						<Text
							margin="0px 0px 0 0px"
							color="--darkL2"
							font="--headline3"
							lg-text-align="left"
							sm-font="--lead"
						>
							Підготовка до іспитів
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					margin="0px 0px 32px 0px"
					lg-width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					align-content="center"
				>
					<Icon
						category="md"
						icon={MdDone}
						size="36px"
						background="--color-secondary"
						color="--light"
						border-radius="50%"
						padding="8px 8px 8px 8px"
					/>
					<Box margin="0px 0px 0px 22px" sm-margin="0px 0px 0px 12px">
						<Text
							margin="0px 0px 0 0px"
							color="--darkL2"
							font="--headline3"
							lg-text-align="left"
							sm-font="--lead"
						>
							Онлайн-курси
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/673d8fbc28b5e30023ae33ed/images/billboard.jpg?v=2024-11-20T07:47:23.250Z"
					max-width="400px"
					border-radius="8px"
					height="100%"
					object-fit="cover"
					lg-width="100%"
					lg-max-width="none"
					srcSet="https://smartuploads.quarkly.io/673d8fbc28b5e30023ae33ed/images/billboard.jpg?v=2024-11-20T07%3A47%3A23.250Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/673d8fbc28b5e30023ae33ed/images/billboard.jpg?v=2024-11-20T07%3A47%3A23.250Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/673d8fbc28b5e30023ae33ed/images/billboard.jpg?v=2024-11-20T07%3A47%3A23.250Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/673d8fbc28b5e30023ae33ed/images/billboard.jpg?v=2024-11-20T07%3A47%3A23.250Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/673d8fbc28b5e30023ae33ed/images/billboard.jpg?v=2024-11-20T07%3A47%3A23.250Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/673d8fbc28b5e30023ae33ed/images/billboard.jpg?v=2024-11-20T07%3A47%3A23.250Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/673d8fbc28b5e30023ae33ed/images/billboard.jpg?v=2024-11-20T07%3A47%3A23.250Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Button
				background="--color-secondary"
				margin="1rem 0px 1rem 0px"
				type="link"
				text-decoration-line="initial"
				href="/poslugi-ta-cini"
			>
				Детальніше
			</Button>
		</Section>
		<Components.Faq />
		<Components.Footer>
			<Override slot="link7" />
			<Override slot="link" />
			<Override slot="text2" />
			<Override slot="link5" />
			<Override slot="link6" />
			<Override slot="text3" />
			<Override slot="link3" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673cdfe29710450018a47394"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\nhtml, body {\nscroll-behavior: smooth;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});